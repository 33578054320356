import { es, enUS, it, fr, de, pt } from 'date-fns/locale';
import { Locale } from 'date-fns';

type LocaleKey = 'es' | 'en' | 'it' | 'fr' | 'de' | 'pt';

const locales: Record<LocaleKey, Locale>  = {
  es: es,
  en: enUS,
  it: it,
  fr: fr,
  de: de,
  pt: pt,
};

export function useLocale(localeParam: string): Locale {
  // Asegurarse de que el localeParam sea un LocaleKey o retornar un valor por defecto
  return locales[localeParam as LocaleKey] || enUS;
}