"use client";

import React, { useEffect, useRef } from "react";

const MultiBanner300x250: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      // Limpia el contenedor para evitar duplicados
      containerRef.current.innerHTML = "";

      // Genera el URI dinámico para el iframe
      const uri =
        "https://www.gambling-affiliation.com/cpm/i=yfmd9N20zmhoP7WSE5XKzKxi2THcnmHaUR91TWcRVdw_GA7331V2&aff_var_1=&" +
        String(Math.random()).substring(2, 11);

      // Crea el iframe manualmente
      const iframe = document.createElement("iframe");
      iframe.src = uri;
      iframe.width = "300"; // Ancho del anuncio
      iframe.height = "250"; // Altura del anuncio
      iframe.frameBorder = "0";
      iframe.marginWidth = "0";
      iframe.marginHeight = "0";
      iframe.scrolling = "no";
      iframe.style.border = "none"; // Opcional: elimina bordes si los hubiera

      // Inserta el iframe en el contenedor
      containerRef.current.appendChild(iframe);
    }
  }, []);

  return <div ref={containerRef}></div>;
};

export default MultiBanner300x250;
