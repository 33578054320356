'use client'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './table.scss'
import { Header, TableKeys } from '@/types/types';
import useWindowSize from '@/hooks/useWindowSize';
import { useTranslations } from 'next-intl';

interface TableProps {
  headers: Header[];
  rows: React.ReactNode[];
  mockedRows?: React.ReactNode[];
  subRows?: React.ReactNode[];
  totalPages?: number;
  currentPage?: number;
  sortBy: TableKeys;
  sortDirection: 'asc' | 'desc';
  onSortChange?: (value: TableKeys, direction: 'asc' | 'desc') => void;
  onPageChange?: (page: number) => void;
  observeLastRow?: any
  loading: boolean;
}

function debounce(func: (...args: any[]) => void, delay: number) {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

const Table: React.FC<TableProps> = ({ 
  headers,
  rows,
  mockedRows,
  subRows = [],
  totalPages = 1,
  currentPage = 1,
  sortBy,
  sortDirection,
  onSortChange = () => {},
  onPageChange = () => {},
  loading = true,
  observeLastRow
}) => {
  const [requestedPage, setRequestedPage] = useState(currentPage);  // Estado temporal para la página solicitada
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const { width } = useWindowSize();
  const t = useTranslations('table');

  useEffect(() => {
    setRequestedPage(currentPage)
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setRequestedPage(page);
    debouncedOnPageChange(page);
  };

  const handlePrevPage = () => {
    if (requestedPage > 1) {
      handlePageChange(requestedPage - 1);
    }
  };

  const handleNextPage = () => {
    if (requestedPage < totalPages) {
      handlePageChange(requestedPage + 1);
    }
  };

  const handlePageInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const page = Number(event.target.value);
    if (page >= 1 && page <= totalPages) {
      handlePageChange(page);
    }
  };

  const handleRowClick = (trendId: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(trendId)) {
      newExpandedRows.delete(trendId);
    } else {
      newExpandedRows.add(trendId);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleSort = (sortKey: TableKeys) => {
    let direction: 'asc' | 'desc' = sortKey === "date" ? "asc" : "desc";

    if (sortBy === sortKey) {
      direction = sortDirection === 'asc' ? 'desc' : 'asc';
    }
    onSortChange(sortKey, direction);
  };

  const debouncedOnPageChange = useCallback(
    debounce((page: number) => {
      onPageChange(page);
    }, 500),
    [onPageChange]
  );

  return (
    <div className="glass-segment">
      <table className="tableFont w-100 overflox-x-scroll">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th 
                style={ header?.sortKey ? { cursor: 'pointer'} : {}}
                className={`sortable-header ${header.sortKey === sortBy ? (sortDirection === 'asc' ? 'asc' : 'desc') : ''}`}
                key={index} 
                onClick={() => header.sortKey && handleSort(header.sortKey as TableKeys)}>
                {header?.label}
              {/* {header?.sortKey === sortBy && (sortDirection === 'asc' ? '▲' : '▼')} */}
            </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {!loading && rows?.map((row, i) => {
          const trendId = (row as React.ReactElement).key as string;
          return (
            <React.Fragment key={trendId}>
              <tr className='mainRow' ref={i === rows.length - 19 ? observeLastRow : null} onClick={() => handleRowClick(Number(trendId))}>
                {row}
              </tr>
              {subRows.length > 0 && expandedRows.has(Number(trendId)) && (
                <tr className='subRow'>
                <td colSpan={12}>
                  <table className="table-subtable">
                    <tbody>
                      <tr>
                        {subRows[i]}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              )}
            </React.Fragment>
          )
        })}
        {loading && mockedRows?.map((row, i) => {
            const trendId = (row as React.ReactElement).key as string;
            return (
              <React.Fragment key={trendId + i}>
                <tr className="skeleton-row">
                  {row}
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
      {totalPages > 1 &&
      <div className="pagination-controls">
        <button className="btn btn-primary-outline btn-sm" onClick={handlePrevPage} disabled={currentPage === 1}>
          {t('previous')}
        </button>
        <input
          className="form-control form-control-sm"
          type="number"
          value={requestedPage}
          onChange={handlePageInput}
          min="1"
          max={totalPages}
        />
        <span className='w-100'>{t('ofPages')} {totalPages}</span>
        <button className="btn btn-primary-outline btn-sm" onClick={handleNextPage} disabled={currentPage === totalPages}>
          {t('next')}
        </button>
      </div>
      }
    </div>
  );
};

export default Table;